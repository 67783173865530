<template>
  <main id="inspo">
        <img class="prev" src="../assets/chevron-left.svg" alt="next" @click="$emit('move', { transitionType: 'prev', to: '/information' })">
    <img class="next" src="../assets/chevron-right.svg" alt="next" @click="$emit('move', { transitionType: 'next', to: '/kontakt' })">
    <section class="content">
      <section class="intro" v-hammer:swipe.left.right="goTo">
        <pager :activePage="5" />
        <h1 class="main">Inspiration</h1>
      </section>
      <section class="floating">
        <ingressarticle v-for="(article, index) of articles" :key="index" :index="index" :data="article" />
      </section>
    </section>
  </main>
</template>

<script>
import pager from '@/components/Pager';
import ingressarticle from '@/components/articles/FlowArticle';

export default {
  name: 'inspiration',
  components: {
    pager,
    ingressarticle
  },
  methods: {
    goTo(e){
      if(e.direction === 2) {
        this.$emit('move', { transitionType: 'next', to: '/kontakt' });
      } else {
        this.$emit('move', { transitionType: 'prev', to: '/information' });
      }
    }
  },
  computed: {
    articles(){
      return this.$store.getters.inspiration;
    }
  }
}
</script>
<style lang="scss">
@import '../scss/variables';

#inspo {

  .intro {
    background: $pink !important;
    position: relative;

    .pager {
      position: absolute;
      top: 0;
    }
  }

  .floating {
    padding: 0 !important;
    background: none !important;
  }
}
</style>