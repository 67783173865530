<template>
    <article class="ingress-article">
        <header>
            <figure v-if="data.media">
                <img v-if="data.media.url" :src="data.media.url" :alt="data.title">
            </figure>
            <h1>{{data.title}}</h1>
            <p class="ingress" v-if="data.ingress">{{data.ingress}}</p>
        </header>
        <main class="content" v-html="content" v-show="readMore" :class="{ active : readMore }" />
        <a href="#" class="readmore" @click=" $router.push(`/inspiration/${($vnode.key*1)+1}`)">Läs mer</a>
    </article>
</template>

<script>
export default {
    name: 'flowarticle',
    props: ['data', 'knowmore'],
    data(){
        return {
            content: '',
            readMore: false
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.ingress-article {
    border-radius: $radius;
    background: white;
    margin-bottom: 1rem;
    padding-bottom: .01rem;

    header {

        figure {
            
            img {
                border-top-left-radius: $radius;
                border-top-right-radius: $radius;
                width: 100%;
            }
        }

        h1 {
            margin: 1rem 0 0 0;
            padding: 0 1rem;
        }

        .ingress {
            margin: 1rem;
            font-weight: 400;
            font-size: 1rem;
        }
    } 

    .content {
        padding: 1rem !important;
        
        .active {
            background: red;
        }

        ul {
            list-style: none !important;

            li {
                padding: 0 .75rem;
                margin: 0 0 1rem 0;
                border-left: .25rem solid rgba($color: #000000, $alpha: .2);

                p {
                    margin: 0;
                    padding: 0;
                    font-style: italic;
                    font-size: .8rem;
                }
            }
        }
    }

    .readmore {
        @extend %center;
        height: 3rem;
        margin: 1rem;
        border: 1px solid rgba($color: $darkgrey, $alpha: 1);
        border-radius: $radius;
        color: $darkgrey;
        text-decoration: none;
        font-weight: 700;
    }
}

</style>
